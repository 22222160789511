import React from "react"
import Layout from "../components/Layout"
import oldTrainingSession from "../images/rac-training-old.jpg"

export default function About() {
  return (
    <Layout>
      <div class="content">
        <div class="main-left-block">
          <img src={oldTrainingSession}  class="imageLeftBlock" alt="rhino athletics club training session" />
        </div>
        <div class="main-center-block">
          <p>&nbsp;</p>
        </div>
        <div class="main-right-block">
          <p>The Rhino Athletics Club was founded in 1990 by a small group of sports loving university graduates who wanted to continue participating in organized sports after leaving university.</p>
          <p>Today, the RAC boasts two teams in the National League Charging Rhino (men) and Amazon Rhino (women), and also one of the leading Rugby teams in the country, Rhino Rugby Club.  The RAC has been the most dominant force in the development of young basketball talent in Uganda and the improvement in standards of game refereeing/officiation. We sponsored numerous clinics aimed at developing the skills of our local refereeing as a way of up lifting the general level of basketball competition in the country.</p>
          <p>Through its development programs which have been in place since the club started, RAC has had a significant influence in basketball in Uganda and it is common knowledge to hear that you will find a Rhino in each and every single basketball club in Uganda.</p>
          <p>RAC is known best for instilling the basics of Basketball in young and upcoming players but better still for the levels of discipline that the club prides itself in and for this, in the year 2007, the Charging Rhino were voted the most disciplined team in the country.</p>
        </div>
      </div>
    </Layout>
  )
}